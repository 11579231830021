const dev = {
  baseUrl: "https://us-central1-seamless-dev-53a8d.cloudfunctions.net",
  firebase: {
    apiKey: "AIzaSyBWL1Xysi7ExmXgf4nRs7tBsS_qA708dnQ",
    authDomain: "seamless-dev-53a8d.firebaseapp.com",
    databaseURL: "https://seamless-dev-53a8d.firebaseio.com",
    projectId: "seamless-dev-53a8d",
    storageBucket: "seamless-dev-53a8d.appspot.com",
    messagingSenderId: "142099090132",
    appId: "1:142099090132:web:123a62c88d0d409910c941",
    measurementId: "G-SDMYCRG6ZQ",
  },
}

const stg = {
  baseUrl: "https://us-central1-seamless-stg.cloudfunctions.net",
  firebase: {
    apiKey: "AIzaSyCJznAn81w94ubG-FkdFsSX1M2KEUxSbr0",
    authDomain: "seamless-stg.firebaseapp.com",
    databaseURL: "https://seamless-stg.firebaseio.com",
    projectId: "seamless-stg",
    storageBucket: "seamless-stg.appspot.com",
    messagingSenderId: "793690324783",
    appId: "1:793690324783:web:4a8cdba75604b51c40abda",
    measurementId: "G-TN8J48SNK8",
  },
}

const prod = {
  baseUrl: "https://us-central1-seamless-pro.cloudfunctions.net",
  firebase: {
    apiKey: "AIzaSyBgefMI0m_Um5oOKQKlr5X0zexNmZyKsJw",
    authDomain: "seamless-pro.firebaseapp.com",
    databaseURL: "https://seamless-pro.firebaseio.com",
    projectId: "seamless-pro",
    storageBucket: "seamless-pro.appspot.com",
    messagingSenderId: "865115846369",
    appId: "1:865115846369:web:d1cfea78766d04fab5e9a0",
    measurementId: "G-JCGJ41QDPQ",
  },
}

const config =
  process.env.REACT_APP_ENV === "prod"
    ? prod
    : process.env.REACT_APP_ENV === "dev"
    ? dev
    : stg

const env = {
  // Add common config values here
  ...config,
}
export default env
